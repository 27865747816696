import { graphql, Link } from "gatsby";
import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import Layout from "../components/Layout";

const projects = ({ data }) => {
  const nodes = data.allMarkdownRemark.nodes;
  const type = "projects";
  return (
    <Layout active={type}>
      <title>Projects | DoingCloudStuff</title>
      <Breadcrumb type={type} />
      <h1>Projects</h1>
      <p>Here are a few blog posts I've written over the years.</p>
      <ul>
        {nodes.map(({ frontmatter: { title, slug, type } }) => (
          <li key={slug}>
            <h4>{title}</h4>
            <Link to={`/${type}s/${slug}`}>Read more</Link>
          </li>
        ))}
      </ul>
    </Layout>
  );
};

export const query = graphql`
  {
    allMarkdownRemark(filter: { frontmatter: { type: { eq: "project" } } }) {
      nodes {
        frontmatter {
          author
          title
          slug
          type
        }
      }
    }
  }
`;

export default projects;
